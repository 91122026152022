body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.demo-container {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 20%; }
  .demo-container .title-container {
    width: 80%;
    background-color: #fff;
    margin: 16px;
    padding: 8px; }
  .demo-container h2 {
    text-align: center; }
  .demo-container p {
    font-size: 16px; }
  .demo-container .users-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: 'center'; }
    .demo-container .users-container .user-item {
      padding: 8px;
      cursor: pointer;
      background: white; }
      .demo-container .users-container .user-item img {
        width: 100%;
        height: auto;
        display: block; }
      .demo-container .users-container .user-item h3 {
        font-weight: bold;
        text-align: center;
        margin: 8px; }
      .demo-container .users-container .user-item:hover {
        border: 1px solid lightgray; }

@media screen and (max-width: 639px) {
  .demo-container {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 20%; }
    .demo-container .title-container {
      width: 80%;
      background-color: #fff;
      margin: 16px;
      padding: 8px; }
    .demo-container h2 {
      text-align: center; }
    .demo-container p {
      font-size: 16px; }
    .demo-container .users-container {
      width: 80%;
      display: inline-block; }
      .demo-container .users-container .user-item {
        padding: 8px;
        cursor: pointer;
        background: white; }
        .demo-container .users-container .user-item img {
          width: 100%;
          height: auto;
          display: block; }
        .demo-container .users-container .user-item h3 {
          font-weight: bold;
          text-align: center;
          margin: 8px; }
        .demo-container .users-container .user-item:hover {
          border: 1px solid lightgray; } }

.sortable-container {
  max-width: calc(100%); }
  .sortable-container .sortable {
    display: flex;
    margin-left: 0.5em; }
    .sortable-container .sortable div {
      margin: 0 0.5em 0.5em 0.5em;
      padding: 2px 1em;
      border: 1px solid lightgray;
      cursor: move; }
      .sortable-container .sortable div.selected {
        background: white; }
  .sortable-container button {
    margin: 0.5em 0 0 1em; }

.missing-values-container {
  margin: 0 0 0 16px; }
  .missing-values-container > div {
    width: 96%;
    display: inline-block;
    margin: 0; }
    .missing-values-container > div textarea {
      width: 96%; }

.highlightText-container .selection span {
  display: inline-block;
  background: lightblue;
  padding: 0 5px;
  margin: 8px 8px 0 0; }

.highlightText-container .btn-validate {
  margin: 16px 0 0 0; }

.instruction-bubble, .message-bubble {
  margin: 1em 1em 2em 1em; }
  .instruction-bubble div, .message-bubble div {
    display: inline-block;
    padding: 1em;
    background: #fff;
    color: #333;
    border-radius: 16px 16px 16px 0px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: calc(80%); }

.instruction-bubble div {
  background: rgba(192, 76, 42, 0.6);
  color: white; }

.message-bubble div {
  display: inline-block;
  padding: 1em;
  background: #fff;
  border-radius: 16px 16px 16px 0px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-width: calc(80%); }

.message-bubble.right {
  text-align: right; }
  .message-bubble.right div {
    background-color: #5698fc;
    color: white;
    border-radius: 16px 16px 0px 16px; }

.qcm-container {
  padding: 0 0 0 16px; }
  .qcm-container .qcm-item {
    display: flex;
    align-items: center;
    margin: 0 0 8px 0; }
    .qcm-container .qcm-item input[type="radio"] {
      box-sizing: content-box; }
    .qcm-container .qcm-item input, .qcm-container .qcm-item label {
      cursor: pointer; }
    .qcm-container .qcm-item label {
      padding: 0 0 0 8px; }

.btn-action {
  max-width: 150px;
  position: relative;
  left: 40%;
  margin-bottom: 2em;
  margin-top: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.75em;
  text-align: center; }

.btn-choice {
  text-align: center; }
  .btn-choice button {
    margin: 0 8px; }

.chat {
  margin: auto;
  overflow-y: auto;
  background-color: #f0f2f4; }
  .chat .scrollable {
    height: 80vh; }

.bot-avatar {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); }

.button-action {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer; }

.button-action:hover {
  color: #40a9ff;
  border-color: #40a9ff; }

.chatbot .chat .inputText {
  display: inline-block;
  width: 100%;
  background: white;
  border: 1px solid lightgrey;
  padding: 5px 8px;
  border-radius: 3px; }

.chatbot .card {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 1em auto; }
  .chatbot .card .title {
    margin: 0 0 1em 0; }
  .chatbot .card button {
    display: block;
    margin: 0 auto; }


.dashboard-container.teacher .classroom-container {
  margin: 2em 0; }

.dashboard-container.teacher ul li {
  width: 20%;
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  background: white;
  box-sizing: border-box;
  text-align: center; }
  .dashboard-container.teacher ul li a {
    display: block; }
  .dashboard-container.teacher ul li img {
    width: 100%;
    height: auto;
    display: block; }
  .dashboard-container.teacher ul li span {
    font-weight: bold;
    text-align: center;
    margin: 0; }

.dashboard-container > h3, .classroom-container > h3 {
  margin-top: 2em;
  font-weight: bold; }

@media screen and (max-width: 639px) {
  .dashboard-container.teacher ul li {
    width: 100%;
    margin: 0 0 0.2em 0; } }

.errorBanner {
  left: 0px;
  top: 60px;
  z-index: 10;
  font-weight: bold;
  position: fixed;
  line-height: 60px;
  background: #ff8100;
  margin-top: 0;
  text-align: center;
  width: 100%; }

button.report-btn {
  display: block;
  margin: auto; }

#teacher-gallery .btn-validate {
  display: block;
  margin: 2em auto; }

#teacher-gallery .steps-container {
  margin: 2em 0 0 0; }

.classroom-container .student-item {
  width: 20%;
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  background: white;
  box-sizing: border-box; }
  .classroom-container .student-item img {
    width: 100%;
    height: auto;
    display: block; }
  .classroom-container .student-item p {
    font-weight: bold;
    text-align: center;
    margin: 0; }
  .classroom-container .student-item:hover {
    border: 1px solid lightgray; }

.classroom-container a {
  font-size: 1em;
  float: right;
  font-weight: normal;
  color: red; }

.classroom-container h3 > a {
  font-size: 1em;
  float: right;
  font-weight: normal;
  color: #1890ff; }

#main-nav {
  margin-left: auto; }
  #main-nav .mobile-menu-open, #main-nav .mobile-menu-close {
    display: none;
    cursor: pointer; }
  #main-nav .mobile-menu-open span {
    margin: 9px 1em;
    color: white;
    font-size: 32px; }
  #main-nav .mobile-menu-open .anticon-menu {
    color: white;
    width: 20px;
    margin-right: 15px; }
  #main-nav .mobile-menu-close span {
    color: #333; }
  #main-nav .menu-container .nav-item {
    display: inline-block; }
    #main-nav .menu-container .nav-item a {
      display: block;
      padding: 1.4em 1.5em;
      color: white; }
      #main-nav .menu-container .nav-item a .anticon-down, #main-nav .menu-container .nav-item a .anticon-up {
        padding-left: 10px; }
        #main-nav .menu-container .nav-item a .anticon-down svg, #main-nav .menu-container .nav-item a .anticon-up svg {
          width: 10px; }
      #main-nav .menu-container .nav-item a span {
        padding: 0 0 0 8px; }
      #main-nav .menu-container .nav-item a.selected, #main-nav .menu-container .nav-item a:hover {
        background: #f0f2f4;
        color: #333; }

@media screen and (max-width: 1194px) {
  #main-nav .mobile-menu-open, #main-nav .mobile-menu-close {
    display: block; }
  #main-nav .menu-container {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    height: 100%;
    padding: 1em;
    background: white;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    -webkit-transform: translate3d(300px, 0, 0);
            transform: translate3d(300px, 0, 0);
    transition: all 0.2s ease-out; }
    #main-nav .menu-container.open {
      -webkit-transform: translateZ(0);
      transform: translateZ(0); }
    #main-nav .menu-container .nav-item {
      flex-grow: 1;
      flex-basis: 0;
      padding: 15px;
      display: block;
      max-width: 230px; }
      #main-nav .menu-container .nav-item a {
        padding: 0.3em 0.8em;
        font-weight: bold;
        text-align: center;
        color: #333; }
        #main-nav .menu-container .nav-item a.selected, #main-nav .menu-container .nav-item a:hover {
          background: white;
          color: #C04D29; }
        #main-nav .menu-container .nav-item a .anticon-down, #main-nav .menu-container .nav-item a .anticon-up {
          display: block;
          padding: 0; }
        #main-nav .menu-container .nav-item a span {
          padding: 0;
          display: block; }
      #main-nav .menu-container .nav-item .modules-list-side {
        position: absolute;
        left: 0;
        background-color: #f0f2f4;
        color: #333;
        z-index: 1; }
        #main-nav .menu-container .nav-item .modules-list-side div {
          width: 100%; }
          #main-nav .menu-container .nav-item .modules-list-side div a {
            font-size: 12px;
            margin: 1em;
            font-weight: normal;
            background: white;
            line-height: 18px;
            text-align: left;
            padding: 10px 0 10px 5px; }
    #main-nav .menu-container .btn-logout {
      position: fixed;
      top: 0;
      right: 0; }
      #main-nav .menu-container .btn-logout:hover {
        background: white; }
      #main-nav .menu-container .btn-logout a {
        padding: 0; }
        #main-nav .menu-container .btn-logout a:hover {
          background: white; }
        #main-nav .menu-container .btn-logout a i {
          display: block;
          font-size: 18px; }
        #main-nav .menu-container .btn-logout a span {
          display: none; } }

#logo {
  background-color: white; }
  #logo a {
    display: inline-block; }
    #logo a img {
      display: inline-block; }
      #logo a img.logo.hatier {
        padding: 0 16px 0 0; }
      #logo a img.logo.evidenceb {
        width: auto;
        height: 46px;
        padding: 0 16px 0 0; }

#footer {
  display: flex;
  padding: 2em 0;
  text-align: center;
  background-color: #333; }
  #footer .col {
    width: 50%; }
    #footer .col span {
      display: block;
      margin: 0 auto;
      color: white; }
      #footer .col span.bold {
        font-size: 16px;
        font-weight: 600; }
    #footer .col img {
      display: block;
      margin: 1em auto 0 auto;
      width: 50px;
      height: auto; }

@media screen and (max-width: 639px) {
  #footer {
    display: none; } }

#layout {
  position: relative; }
  #layout #header {
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    width: 100%;
    align-items: center;
    background: #C04D29;
    z-index: 2; }
    #layout #header .break {
      height: 0;
      flex-basis: 100%; }
    #layout #header .modules-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0.5em;
      background: #f0f2f4;
      color: #C04D29;
      z-index: 35; }
      #layout #header .modules-list .module-item {
        color: white;
        margin: 0.5em;
        flex: 1 0 32%;
        text-align: center;
        min-height: 70px; }
        #layout #header .modules-list .module-item a {
          padding: 0.5em;
          height: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-direction: column;
          color: #C04D29;
          background: white; }
          #layout #header .modules-list .module-item a:hover {
            background: #C04D29;
            color: white; }



body {
  background-color: #333; }

@media screen and (max-width: 639px) {
  body {
    background: #f0f2f4; }
  #_hj-f5b2a1eb-9b07_feedback {
    z-index: 10; }
  #_hj-f5b2a1eb-9b07_feedback[data-minimized-position="bottom_right"] ._hj-f5b2a1eb-9b07_hotjar_buddy {
    bottom: 90vh;
    left: -90px; } }

#root {
  background-color: #f0f2f4; }

#content {
  position: relative;
  width: 100%;
  max-width: 960px;
  min-height: 70vh;
  padding: 7em 2em 2em 2em;
  margin: 0 auto;
  z-index: 1; }

@media screen and (max-width: 639px) {
  #content {
    padding-bottom: 60px; } }

.clear {
  clear: both; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.centered-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .centered-container > div {
    text-align: center; }

.bold {
  font-weight: 600; }

/*CARDS*/
.card {
  width: 100%;
  padding: 2em;
  background-color: white;
  border: 1px solid #e8e8e8; }
  .card .title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px; }

/*GRID*/
.row.flex {
  display: flex; }

.row.margin-0-5em > div {
  margin: 0 0.5em; }

.row.margin-1em > div {
  margin: 0 1em; }

.row.margin-2em > div {
  margin: 0 1em; }

.row > div {
  flex-grow: 1;
  flex-basis: 0; }

@media screen and (max-width: 768px) {
  .row.flex {
    display: block; }
  .row > div {
    width: 100%; } }

/*BREADCRUMBS*/
.breadcrumb {
  margin: 0 0 1em 1em;
  font-size: 12px; }
  .breadcrumb span {
    font-weight: 600; }

/*BUTTONS*/
.btn {
  display: inline-block;
  border-radius: 3px;
  background: none;
  cursor: pointer;
  padding: 3px 1em; }

.btn-validate {
  background-color: #1890ff;
  border: none;
  color: white; }
  .btn-validate:hover {
    background-color: #40a9ff; }


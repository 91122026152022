.chatbot{
    .chat{
        .inputText{display: inline-block; width: 100%; background: white; border: 1px solid lightgrey; padding: 5px 8px; border-radius: 3px;}
    }
    .card{ width: 100%; max-width: 800px; margin: 0 auto 1em auto;
        .title{margin: 0 0 1em 0;}
        button{display:block; margin: 0 auto;}
    }
}


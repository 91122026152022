@import "./variables.scss";

#layout{ position: relative;
    #header{ display: flex; flex-wrap: wrap; position: fixed; width: 100%; align-items: center; background: $primaryColor; z-index: 2;
        .break {height: 0; flex-basis: 100%;}
        .modules-list{ display: flex; flex-wrap: wrap; padding: 0.5em; background: rgb(240,242,244); color:#C04D29; z-index:35;
            .module-item { color: white; margin: 0.5em; flex: 1 0 32%; text-align: center; min-height: 70px;
                a { padding: 0.5em; height: 100%; display: flex; justify-content: center; align-content: center; flex-direction: column; color: #C04D29; background: white; 
                &:hover { background: #C04D29; color: white; }
                }
            }
        }
    }
}